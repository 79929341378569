@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background-color: #f7f7f7;
}

body {
  margin: 0;
  min-height: 100vh;
  overflow-y: scroll;
}

#root {
  background-color: #f7f7f7;
}

#app {
  background-color: #f7f7f7;
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
}

.cky-notice .cky-title {
  font-size: 0;
  line-height: 0;
}
.cky-notice .cky-notice-btn-wrapper {
  text-decoration: none;
}
.cky-consent-bar .cky-notice-group {
  display: flex !important;
}
.cky-btn.cky-btn-accept {
  background: #4a94f7;
  border-color: #4a94f7;
}
.cky-btn.cky-btn-customize {
  color: #4a94f7 !important;
  border-color: #4a94f7 !important;
}
.cky-consent-bar .cky-banner-btn-close img {
  width: 15px;
  height: 15px;
  margin-top: 5px;
  margin-right: 0px;
}
@media (max-width: 768px) {
  .cky-consent-bar .cky-notice-group {
    display: block !important;
  }
  .cky-notice-btn-wrapper {
    margin-top: 10px;
  }
}
